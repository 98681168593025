import React from "react";
import Navigation from "../reusable/Navigation";
import Footer from "../reusable/Footer";
import Logo from "../assets/logo/1.png";
import Uif from "../assets/blogs/mortgage-providers/uif.webp";
import Guidance from "../assets/blogs/mortgage-providers/guidance.webp";
import Devon from "../assets/blogs/mortgage-providers/devon.webp";
import Lariba from "../assets/blogs/mortgage-providers/lariba.webp";
import Ansar from "../assets/blogs/mortgage-providers/ansar.webp";
import Eqraz from "../assets/blogs/mortgage-providers/eqraz.webp";
import UmFin from "../assets/blogs/mortgage-providers/um.webp";
import Canhalal from "../assets/blogs/mortgage-providers/canhalal.webp";

function HalalMortgageComparison() {
  return (
    <div className="App mx-auto container">
      <Navigation />
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
              The Top 7 Halal Mortgage Providers in 2023
            </h2>
            <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
              First Time Home Buyers Guide
            </p>
          </div>
        </div>
      </section>
      {/* <section className="thumbnails">
        <div className="flex">
          <div className="shadow-lg w-48">
            <figure className="mr-10">
              <img src="#" alt="halal logo" width="250" />
            </figure>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              <p>Noteworthy technology acquisitions 2021</p>
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              <p>
                Here are the biggest enterprise technology acquisitions of 2021
                so far, in reverse chronological order.
              </p>
            </p>
          </div>
        </div>
      </section> */}
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 flex flex-wrap">
        <div className="px-4 mx-auto max-w-screen-xl">
          <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
            <header className="mb-4 lg:mb-6 not-format">
              <address className="flex items-center mb-6 not-italic">
                <div className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                  <img
                    className="mr-4 w-16 h-16 rounded-full"
                    src={Logo}
                    alt="halal baqqal logo"
                  />
                  <div>
                    <a
                      href="test.html"
                      rel="author"
                      className="text-xl font-bold text-gray-900 dark:text-white"
                    >
                      Rashad Bayram
                    </a>

                    <p className="text-base font-light text-gray-500 dark:text-gray-400">
                      <time dateTime="2023-02-27" title="February 27th, 2023">
                        July 24, 2023
                      </time>
                    </p>
                  </div>
                </div>
              </address>
            </header>
            <div className="body">
              <div className="preface">
                <p className="lead">Assalamu Alaikum</p>
                <p>
                  Getting a halal mortgage that complies with Islamic finance
                  principles can be tricky. I've compiled this guide to the top
                  financial institutions which are halal mortgage providers in
                  2023 to make your search easier. Whether you're looking for
                  competitive rates, flexible repayment options, or outstanding
                  customer service - these lenders have you covered. The
                  financial services they provide are 100% Sharia Law compliant.
                </p>
                <h2 id="selection">Selection Process</h2>
                <p>
                  Here are some tips on the process for selecting a halal
                  mortgage provider and what to look for. The ideal process
                  would be:
                </p>
                <ul>
                  <li>
                    Start by identifying financial institutions that offer halal
                    financing options in your area. Look for established
                    providers with a history of compliant lending.
                  </li>
                  <li>
                    Research and compare interest rates, fees, and product
                    offerings across shortlists of providers. Look closely at
                    repayment models - some may be more compliant than others.{" "}
                  </li>
                  <li>
                    Schedule initial consultations with 2-3 top choices to
                    assess customer service and how well they explain the
                    process. Look for transparent communication.
                  </li>
                  <li>
                    Ask detailed questions on their financing structure and how
                    they ensure Sharia compliance. Request a sample financing
                    agreement.
                  </li>
                  <li>
                    Check for halal certificate (fatwa) from scholars,
                    regulators ad committee. Lack of external oversight is a red
                    flag.
                  </li>
                  <li>
                    Read reviews online and ask family and friends for referrals to
                    gauge overall reputation.
                  </li>
                  <li>
                    Compare overall costs, services, and intuitiveness in
                    choosing the best-fit provider for your needs.
                  </li>
                </ul>
                <h3>The most critical criteria are:</h3>
                <ul>
                  <li>Demonstrated experience and longevity in halal financing</li>
                  <li>Competitive rates and reasonable fees</li>
                  <li>Strong customer service and communication</li>
                  <li>Evidence of oversight by Sharia scholars</li>
                  <li>Halal certificate (fatwa) from recognized entity</li>
                </ul>
                <h3>Red flags include:</h3>
                <ul>
                  <li>
                    Vague explanations of how financing complies with Islamic
                    principles
                  </li>
                  <li>Lack of external Sharia auditing procedures</li>
                  <li>Negative complaints about non-transparency</li>
                  <li>
                    Higher than average fees/rates without reasonable
                    justification
                  </li>
                </ul>
                <p>
                  The search process takes time but focuses on providers who
                  instill trust and demonstrate their commitment to ethical,
                  halal financing principles.
                </p>
              </div>
              <div id="uif">
                <h2>
                  <a
                    href="https://www.myuif.com/home-financing/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    UIF
                  </a>
                </h2>
                <p>
                  Voted as the #1 Islamic Financing Institution in the U.S. from
                  2015 through 2019 & as the #1 Islamic Real Estate Financier
                  (Global Award) in 2020 & 2021!
                </p>
                <figure className="#">
                  <img
                    src={Uif}
                    srcSet={Uif}
                    sizes="(max-width: 500px) 500px"
                    alt="uif main page"
                  />
                </figure>
                <h3>Key Features:</h3>
                <ul>
                  <li>
                    Home financing 10, 15, 20, or 30-year monthly payments.
                  </li>
                  <li>No monthly LLC fees</li>
                  <li>Downpayment starts as low as 3% </li>
                </ul>
                <h3 id="fincost">Financing Types:</h3>
                <ul>
                  <li>Fixed-rate</li>
                </ul>
                <h3 id="downpayment">Average rates:</h3>
                <ul>
                  <li>Competitive market rates</li>
                </ul>
                <h3 id="closing">
                  Estimate Cost of Financing Scenario and Est. Out-of-Pocket
                  expenses:
                </h3>
                <ul>
                  <li>
                    For the $150k loan = $6,000-$9,000 in annual cost of
                    financing.{" "}
                  </li>
                  <li>
                    The typical out-of-pocket expenses scenario will be 3-20%
                    down ($4,500-$7,500) + $2,000 closing cost.
                  </li>
                </ul>
                <h3 id="payments">Pros:</h3>
                <p>
                  Among the significant Pros, UIF home financing has been an
                  award-winning company for nine years.
                </p>
                <h3>Cons:</h3>
                <p>
                  {" "}
                  UIF home financing is available only for U.S. residents and
                  only in 22 states
                </p>
              </div>
              <div id="guidance">
                <h2>
                  <a
                    href="https://www.guidanceresidential.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Guidance Residential
                  </a>
                </h2>
                <p>
                  Offering home financing in over 35 states, Guidance
                  Residential has funded over $3 billion in halal loans. Their
                  experienced team takes you through each step, from application
                  to closing. No prepayment penalties give you flexibility.{" "}
                </p>
                <figure className="#">
                  <img
                    src={Guidance}
                    srcSet={Guidance}
                    sizes="(max-width: 500px) 500px"
                    alt="Guidance main page"
                  />
                </figure>
                <h3>Key Features</h3>
                <ul>
                  <li>Financing available for new purchases and refinances</li>
                  <li>No prepayment penalties</li>
                  <li>Fast approvals and closings</li>
                </ul>
                <h3>Financing Types:</h3>
                <ul>
                  <li>Home Purchase</li>
                  <li>Refinance</li>
                </ul>
                <h3>Average Rates:</h3>
                <ul>
                  <li>4.25-5.5%</li>
                </ul>
                <h3>
                  Estimate Cost of Financing Scenario and Est. Out-of-Pocket
                  expenses:
                </h3>
                <ul>
                  <li>
                    For the $150k loan = $6,375-$8,250 in annual cost of
                    financing.
                  </li>
                  <li>
                    The typical out-of-pocket expenses scenario will be 3-5%
                    down ($4,500-$7,500) + $2,000 closing costs.
                  </li>
                </ul>
                <h3 id="calculations">Pros:</h3>
                <ul>
                  <li>No prepayment penalties</li>
                  <li>Fast process</li>
                </ul>
                <h3>Cons:</h3>
                <ul>
                  <li>It is limited to 35 U.S. states.</li>
                </ul>
              </div>
              <div id="devon">
                <h2>
                  <a
                    href="https://www.devonbank.com/faith-based-financing/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Devon Bank
                  </a>
                </h2>
                <p>
                  Chicago-based Devon Bank offers various halal mortgage
                  options, including fixed-rate, ARM, and construction
                  financing. Local bankers provide personalized services for the
                  Chicagoland Muslim community. Devon Bank maintains an on-staff
                  Sharia advisor to ensure the funding adheres to Islamic
                  principles.
                </p>
                <figure className="#">
                  <img
                    src={Devon}
                    srcSet={Devon}
                    sizes="(max-width: 500px) 500px"
                    alt="Devon main page"
                  />
                </figure>
                <h3 id="expenses">Key Features:</h3>
                <ul>
                  <li>Variety of halal mortgage programs</li>
                  <li>Local Chicago-based bank </li>
                  <li>On-staff Sharia advisor</li>
                </ul>
                <h3>Financing Types:</h3>
                <ul>
                  <li>Fixed</li>
                  <li>ARM</li>
                  <li>Construction</li>
                </ul>
                <h3>Average Rates:</h3>
                <ul>
                  <li>4-6% fixed</li>
                  <li>3-4% ARM</li>
                </ul>
                <h3>
                  Estimate Cost of Financing Scenario and Est. Out-of-Pocket
                  expenses:
                </h3>
                <ul>
                  <li>For a $150k loan = $6,000-$9,000 annual fees. </li>
                  <li>
                    The typical out-of-pocket expenses scenario will be 5-20%
                    down ($7,500-$30,000) + $2,000 closing costs.
                  </li>
                </ul>
                <h3>Pros:</h3>
                <ul>
                  <li>Variety of programs</li>
                  <li>Sharia compliance</li>
                </ul>
                <h3>Cons:</h3>
                <ul>
                  <li>Limited to the Chicago area </li>
                </ul>
              </div>
              <div id="lariba">
                <h2>
                  <a
                    href="https://www.lariba.com/sitephp/index.php"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Lariba
                  </a>
                </h2>
                <p>
                  Lariba has three decades of experience providing Islamic home
                  financing using diminishing partnership models. Their
                  transparent fee structure outlines costs upfront with no
                  hidden charges. Dedicated mortgage consultants guide
                  applications to closing.
                </p>
                <figure className="#">
                  <img
                    src={Lariba}
                    srcSet={Lariba}
                    sizes="(max-width: 500px) 500px"
                    alt="Lariba main page"
                  />
                </figure>
                <h3>Key Features:</h3>
                <ul>
                  <li>Clear fee structure disclosed upfront</li>
                  <li>Consultative service from application to closing</li>
                  <li>Over 30 years of experience</li>
                </ul>
                <h3>Financing Types:</h3>
                <ul>
                  <li>Diminishing partnership</li>
                </ul>
                <h3>Average Rates:</h3>
                <ul>
                  <li>5-6%</li>
                </ul>
                <h3>
                  Estimate Cost of Financing Scenario and Est. Out-of-Pocket
                  expenses:
                </h3>
                <ul>
                  <li>For a $150k loan = $7,500-$9,000 annual cost. </li>
                  <li>
                    The typical out-of-pocket expenses scenario will be 5-20%
                    down ($7,500-$30,000) + $2,000 closing costs.
                  </li>
                </ul>
                <h3>Pros:</h3>
                <ul>
                  <li>Transparent fees</li>
                  <li>Consultative service</li>
                  <li>Established history</li>
                </ul>
                <h3>Cons:</h3>
                <ul>
                  <li>Rates slightly higher</li>
                </ul>
              </div>
              <div id="ansar">
                <h2>
                  <a
                    href="https://www.ansarhousing.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ansar Co-operative Housing Corporation (Canada)
                  </a>
                </h2>
                <p>
                  With over 25 years of experience, Ansar is Canada's leading
                  halal mortgage provider. They offer financing based on the
                  ijara and Murabaha models approved by their Sharia Board.
                  Ansar provides personalized service and guidance for the
                  Canadian Muslim community.
                </p>
                <figure className="#">
                  <img
                    src={Ansar}
                    srcSet={Ansar}
                    sizes="(max-width: 500px) 500px"
                    alt="Lariba main page"
                  />
                </figure>
                <h3>Key Features:</h3>
                <ul>
                  <li>Established provider of ijara and Murabaha financing </li>
                  <li>Services tailored to Canadian Muslims </li>
                  <li>Long-standing Sharia approval</li>
                </ul>
                <h3>Financing Types:</h3>
                <ul>
                  <li>Ijara</li>
                  <li>Murabaha</li>
                </ul>
                <h3>Average Rates:</h3>
                <ul>
                  <li>4-5%</li>
                </ul>
                <h3>
                  Estimate Cost of Financing Scenario and Est. Out-of-Pocket
                  expenses:
                </h3>
                <ul>
                  <li>For a $150k loan = $6,000-$7,500 annual cost. </li>
                  <li>
                    The typical out-of-pocket expenses scenario will be 5-20%
                    down ($7,500-$30,000) + $1,500 closing.
                  </li>
                </ul>
                <h3>Pros:</h3>
                <ul>
                  <li>Established</li>
                  <li>Compliant products</li>
                  <li>Tailored service</li>
                </ul>
                <h3>Cons:</h3>
                <ul>
                  <li>Limited to Canada</li>
                </ul>
              </div>
              <div id="canhalal">
                <h2>
                  <a
                    href="https://www.halalfinancialcorp.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Canadian Halal Financial Corporation (Canada)
                  </a>
                </h2>

                <p>
                  Canadian Halal Financial Corporation is based out of Edmonton,
                  Alberta. The financing products they offer and Canadina law
                  and Shariah Law compliant. Each of the Murabaha and Musharakah
                  agreements used is certified as Sharia Law compliant by an{" "}
                  <a href="https://www.halalfinancialcorp.com/fatwas">
                    Al Azhar Fatwa Committee.
                  </a>
                </p>
                <figure className="#">
                  <img
                    src={Canhalal}
                    srcSet={Canhalal}
                    sizes="(max-width: 500px) 500px"
                    alt="Eqraz main page"
                  />
                </figure>
                <p>
                  Canadian Halal Financial Corporation utilizes its own
                  resources to finance its clients' halal houses. Our clients
                  enter into a Shariah Law-compliant finance contract only with
                  our corporation (no third parties).
                </p>
                <h3>Key Features:</h3>
                <ul>
                  <li>
                    Murabaha and Musharakah agreements certified as Sharia Law
                    compliant
                  </li>
                  <li>
                    Each finance agreement is between the purchaser and the
                    Canadian Halal Financial Corporation{" "}
                  </li>
                  <li>Minimum 20% downpayment required</li>
                </ul>
                <h3>Financing Types:</h3>
                <ul>
                  <li>Murabaha</li>
                  <li>Musharakah</li>
                </ul>
                <h3>Average Rates:</h3>
                <ul>
                  <li>
                    Cost and profit is provided with an amount of monthly
                    payments, which includes all costs and profit.
                  </li>
                </ul>
                <h3>
                  Estimate Cost of Financing Scenario and Est. Out-of-Pocket
                  expenses:
                </h3>
                <ul>
                  <li>
                    The typical out-of-pocket expenses scenario will be 20% +
                    closing cost.
                  </li>
                </ul>
                <h3>Pros:</h3>
                <ul>
                  <li>Strong compliance</li>
                  <li>Ethical company</li>
                </ul>
                <h3>Cons:</h3>
                <ul>
                  <li>20% minimum down payment</li>
                </ul>
              </div>
              <div id="eqraz">
                <h2>
                  <a href="https://eqraz.com/" target="_blank" rel="noreferrer">
                    EQRAZ (Canada)
                  </a>
                </h2>

                <p>
                  EQRAZ provides halal home finance is complaiant with Canadian
                  Law and 100% Shariah based - and AAOIFI-compliant monthly
                  Murabaha mortgage package. With the Murabaha mortgage from
                  EQRAZ, consumers can pay off their mortgage early by paying
                  only the remaining principal and a charge equal to about three
                  months' earnings. You alone have the title, giving you
                  confidence that your house is yours. 100% of the money for
                  EQRAZ comes from shariah-certified, AAOIFI-compliant Wakala
                  Agreements, halal. Within five business days of receiving an
                  application, we actively issue mortgages throughout most
                  provinces because we are fully funded.
                </p>
                <figure className="#">
                  <img
                    src={Eqraz}
                    srcSet={Eqraz}
                    sizes="(max-width: 500px) 500px"
                    alt="Eqraz main page"
                  />
                </figure>
                <h3>Key Features:</h3>
                <ul>
                  <li>Ijara financing for home purchase</li>
                  <li>Strong understanding of Canadian regulation </li>
                  <li>Ethical and transparent practices</li>
                </ul>
                <h3>Financing Types:</h3>
                <ul>
                  <li>Ijara</li>
                </ul>
                <h3>Average Rates:</h3>
                <ul>
                  <li>9.5 - 11.5%</li>
                </ul>
                <h3>
                  Estimate Cost of Financing Scenario and Est. Out-of-Pocket
                  expenses:
                </h3>
                <ul>
                  <li>For a $150k loan = $6,000-$7,500 annual cost.</li>
                  <li>
                    The typical out-of-pocket expenses scenario will be 5-20%
                    down ($7,500-$30,000) + $1,500 closing.
                  </li>
                </ul>
                <h3>Pros:</h3>
                <ul>
                  <li>Strong compliance</li>
                  <li>Ethical company</li>
                </ul>
                <h3>Cons:</h3>
                <ul>
                  <li>Limited to products</li>
                  <li>20% minimum down payment</li>
                </ul>
              </div>
              <div id="umfinancial">
                <h2>
                  <a
                    href="https://umgroup.ca/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    U.M. Financial (Canada)
                  </a>
                </h2>
                <p>
                  U.M. Financial provides halal mortgages based on diminishing
                  musharaka agreements. Their dedicated team of experts guides
                  you through the application and approval process. U.M.
                  Financial is comliant with Canadian Law and aims to promote
                  Islamic banking values across Canada.{" "}
                </p>
                <figure className="#">
                  <img
                    src={UmFin}
                    srcSet={UmFin}
                    sizes="(max-width: 500px) 500px"
                    alt="UmFin main page"
                  />
                </figure>
                <h3>Key Features:</h3>
                <ul>
                  <li>Diminishing musharaka financing agreements</li>
                  <li>Dedicated team with halal financing expertise</li>
                  <li>Promoting Islamic finance values across Canada</li>
                </ul>
                <h3>Financing Types:</h3>
                <ul>
                  <li>Diminishing musharaka</li>
                </ul>
                <h3>Average Rates:</h3>
                <ul>
                  <li>4.5-5.5%</li>
                </ul>
                <h3>
                  Estimate Cost of Financing Scenario and Est. Out-of-Pocket
                  expenses:
                </h3>
                <ul>
                  <li>For a $150k loan = $6,750-$8,250 annual cost.</li>
                  <li>
                    The typical out-of-pocket expenses scenario will be 5-20%
                    down ($7,500-$30,000) + $1,500 closing.
                  </li>
                </ul>
                <h3>Pros:</h3>
                <ul>
                  <li>Promotes Islamic values</li>
                  <li>Expertise in structuring</li>
                </ul>
                <h3>Cons:</h3>
                <ul>
                  <li>Small company</li>
                  <li>Limited reach</li>
                </ul>
              </div>
              <h2>Conclusion</h2>
              <p>
                When choosing a halal financial Institutions offering halal mortgages, consider their
                experience, halal certificate (fatwa), available loan products, compliance with Canadian
                law and customer service. This overview gives you a starting
                point to find the right halal home financing solution for your
                needs. Let me know if you need any other information as you
                begin your search!
              </p>
            </div>
          </article>
        </div>
        <div className="sideBar">
          <div className="ml-5 mr-5 sticky top-0 h-96">
            <h3 className="font-semibold mt-10 ml-4 text-lg">CONTENT</h3>

            <ul className="w-96 h-96 p-5 rounded shadow-lg mb-2 leading-loose">
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#selection">Selection process</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#uif">UIF University Islamic Financial (USA)</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#guidance">Guidance Residential (USA)</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#devon">Devon Bank (USA)</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#lariba">Lariba (USA)</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#ansar">
                  Ansar Co-operative Housing Corporation(Canada)
                </a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#canhalal">
                  Canadian Halal Financial Corporation(Canada)
                </a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#eqraz">EQRAZ(Canada)</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#umfinancial">U.M. Financial (Canada)</a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default HalalMortgageComparison;
