import React from "react";
import { Dropdown } from "flowbite-react";
import Discount from "../alberta_mosques.json";

function Discounts(props) {
  const discounts = Array.from(
    new Set(Discount.map((value) => value.Discount))
  );

  discounts.sort();

  return (
    <Dropdown
      label="Discount %"
      className="overflow-y-auto h-48 w-32"
      style={{ width: 175 }}
    >
      {discounts.map((discount, index) => (
        <div className="w-36" key={index}>
          <Dropdown.Item>
            <input
              // id="cities-checkbox"
              aria-label="checkbox"
              type="checkbox"
              value={discount}
              checked={props.selectedOptions?.includes(discount)}
              onChange={props.handleChange}
              className="w-4 h-4  text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <label
              // htmlFor="calgary-checkbox"
              className="w-full ml-2 text-lg font-medium text-gray-900 dark:text-gray-300"
            >
              {discount}
            </label>
          </Dropdown.Item>
        </div>
      ))}
    </Dropdown>
  );
}

export default Discounts;
