import React, { useState, useEffect } from "react";
import moment from "moment-hijri";

function HijriGregoriandates() {
  const [hijriDate, setHijriDate] = useState("");
  const [gregorianDate, setGregorianDate] = useState("");

  useEffect(() => {
    const updateDates = () => {
      const today = moment();
      setHijriDate(today.format("iYYYY/iM/iD"));
      setGregorianDate(today.format("YYYY/MM/DD"));
    };

    updateDates();
    const interval = setInterval(updateDates, 1000 * 60); // update every minute
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="mx-auto  mb-5" style={{ width: "80%" }}>
      <div className="flex justify-left">
        <div className="mr-3">
          <span className="font-semibold">Hijri date:</span>{" "}
          <span>{hijriDate}</span>
        </div>
        <div>
          <span className="font-semibold">Gregorian date: </span>
          <span>{gregorianDate}</span>
        </div>
      </div>
    </div>
  );
}

export default HijriGregoriandates;
