import React from "react";
import Businesssubmit from "./businessform/Businesssubmit";
import Navigation from "./reusable/Navigation";
import Footerr from "./reusable/Footer";

function Businessform() {
  return (
    <div className="App mx-auto container">
      <Navigation />
      <Businesssubmit />
      <Footerr />
    </div>
  );
}

export default Businessform;
