import React from "react";

import {
  WhatsappShareButton,
  EmailShareButton,
  EmailIcon,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
function ShareIcon() {
  const whatsappURL = "https://web.whatsapp.com/";
  const telegramURL = "https://web.telegram.org/z/";
  const webURL = "https://www.halalbaqqal.com/";
  const title = "Info from HalalBaqqal";
  const emailBody = "Hey, I've found info you might be interested in from ";
  return (
    <div className="fixed right-6">
      <EmailShareButton url={webURL} subject={title} body={emailBody}>
        <EmailIcon className="w-10" round={true} />
      </EmailShareButton>
      <TelegramShareButton url={telegramURL}>
        <TelegramIcon className="w-10" title={title} round={true} />
      </TelegramShareButton>
      <WhatsappShareButton url={whatsappURL}>
        <WhatsappIcon className="w-10" title={title} round={true} />
      </WhatsappShareButton>
    </div>
  );
}

export default ShareIcon;
