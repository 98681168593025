import React, { useState } from "react";

function ZakatCalculator() {
  const [income, setIncome] = useState("");
  const [assets, setAssets] = useState("");
  const [debts, setDebts] = useState("");
  const [expenses, setExpenses] = useState("");
  const [gold, setGold] = useState("");
  const [silver, setSilver] = useState("");
  const [total, setTotal] = useState(0);
  const [isEligible, setIsEligible] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "income") {
      setIncome(value);
    } else if (name === "assets") {
      setAssets(value);
    } else if (name === "debts") {
      setDebts(value);
    } else if (name === "expenses") {
      setExpenses(value);
    } else if (name === "gold") {
      setGold(value);
    } else if (name === "silver") {
      setSilver(value);
    }
  };

  const handleCalculate = () => {
    const incomeNum = Number(income);
    const assetsNum = Number(assets);
    const debtsNum = Number(debts);
    const expensesNum = Number(expenses);
    const goldNum = Number(gold);
    const silverNum = Number(silver);

    const totalWealth =
      incomeNum +
      assetsNum +
      goldNum +
      silverNum / 1000 -
      debtsNum -
      expensesNum;

    if (totalWealth < 0) {
      setTotal(0);
      setIsEligible(false);
      return;
    }

    const nisab = 595;

    if (totalWealth < nisab) {
      setTotal(0);
      setIsEligible(false);
      return;
    }

    const zakat = totalWealth * 0.025;

    setTotal(zakat.toFixed(2));
    setIsEligible(true);
  };

  return (
    <div className="zakat-calculator">
      <h2 className="font-bold text-lg mt-3">Zakat Calculator</h2>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label
            htmlFor="income"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Income
          </label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-96"
            id="income"
            name="income"
            value={income}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label
            htmlFor="assets"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Assets
          </label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-96"
            id="assets"
            name="assets"
            value={assets}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label
            htmlFor="debts"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Debts
          </label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-96"
            id="debts"
            name="debts"
            value={debts}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label
            htmlFor="expenses"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Total Expenses
          </label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-96"
            id="expenses"
            name="expenses"
            value={expenses}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label
            htmlFor="gold"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Gold (grams)
          </label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-96"
            id="gold"
            name="gold"
            value={gold}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group col-md-6">
          <label
            htmlFor="silver"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Silver (grams)
          </label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-96"
            id="silver"
            name="silver"
            value={silver}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <button
        className="p-2 items-center py-2.5 mt-3 mb-3 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        onClick={handleCalculate}
      >
        CALCULATE ZAKAT
      </button>
      {isEligible ? (
        <div className="result-success">
          <p>You are eligible to pay Zakat</p>
          <p>Your Zakat is ${total}</p>
        </div>
      ) : (
        <div className="result-failure">
          <p>You are not eligible to pay Zakat</p>
          <p>Your total wealth is below the Nisab (minimum amount)</p>
        </div>
      )}
    </div>
  );
}

export default ZakatCalculator;
