import React from "react";
import Navigation from "./reusable/Navigation";
import Footerr from "./reusable/Footer";

const Terms = () => {
  return (
    <div className="App container mx-auto test">
      <Navigation />
      <>
        <section className="bg-white dark:bg-gray-900">
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
              <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                HalalBaqqal Terms & Conditions
              </h2>
              <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
                Please read our terms and consitions to make sure that you are
                aware of our website utilization rules.
              </p>
            </div>

            <article className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <div className="flex justify-between items-center mb-5 text-gray-500"></div>
              <h2>Terms and Conditions</h2>
              <h3>Welcome to HalalBaqqal!</h3>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                These terms and conditions outline the rules and regulations for
                the use of HalalBaqqal's Website, located at{" "}
                <a
                  href="https://www.halalbaqqal.com"
                  className="hover:text-blue-700"
                >
                  https://www.halalbaqqal.com
                </a>
              </p>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                By accessing this website we assume you accept these terms and
                conditions. Do not continue to use HalalBaqqal if you do not
                agree to take all of the terms and conditions stated on this
                page.
              </p>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                The following terminology applies to these Terms and Conditions,
                Privacy Statement and Disclaimer Notice and all Agreements:
                "Client", "You" and "Your" refers to you, the person log on this
                website and compliant to the Company’s terms and conditions.
                "The Company", "Ourselves", "We", "Our" and "Us", refers to our
                Company. "Party", "Parties", or "Us", refers to both the Client
                and ourselves. All terms refer to the offer, acceptance and
                consideration of payment necessary to undertake the process of
                our assistance to the Client in the most appropriate manner for
                the express purpose of meeting the Client’s needs in respect of
                provision of the Company’s stated services, in accordance with
                and subject to, prevailing law of Netherlands. Any use of the
                above terminology or other words in the singular, plural,
                capitalization and/or he/she or they, are taken as
                interchangeable and therefore as referring to same.
              </p>
              <h3>Cookies</h3>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                We employ the use of cookies. By accessing HalalBaqqal, you
                agreed to use cookies in agreement with the HalalBaqqal's
                Privacy Policy. Most interactive websites use cookies to let us
                retrieve the user’s details for each visit. Cookies are used by
                our website to enable the functionality of certain areas to make
                it easier for people.
              </p>
              <h3>License</h3>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                Unless otherwise stated, HalalBaqqal and/or its licensors own
                the intellectual property rights for all material on Halal
                Baqqal. All intellectual property rights are reserved. You may
                access this from HalalBaqqal for your own personal use subjected
                to restrictions set in these terms and conditions.
              </p>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                You must not:
              </p>
              <ul className="mb-5 font-light text-gray-500 dark:text-gray-400">
                <li>1. Republish material from HalalBaqqal</li>
                <li>2. Sell, rent or sub-license material from HalalBaqqal</li>
                <li>
                  3. Reproduce, duplicate or copy material from HalalBaqqal
                </li>
                <li>4. Redistribute content from HalalBaqqal</li>
              </ul>
              <h2>Log Files</h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                Parts of this website offer an opportunity for users to post and
                exchange opinions and information in certain areas of the
                website. HalalBaqqal does not filter, edit, publish or review
                Comments prior to their presence on the website. Comments do not
                reflect the views and opinions of HalalBaqqal,its agents and/or
                affiliates. Comments reflect the views and opinions of the
                person who post their views and opinions. To the extent
                permitted by applicable laws, HalalBaqqal shall not be liable
                for the Comments or for any liability, damages or expenses
                caused and/or suffered as a result of any use of and/or posting
                of and/or appearance of the Comments on this website. Halal
                Baqqal reserves the right to monitor all Comments and to remove
                any Comments which can be considered inappropriate, offensive or
                causes breach of these Terms and Conditions.
              </p>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                You warrant and represent that:
              </p>
              <ul className="mb-5 font-light text-gray-500 dark:text-gray-400">
                <li>
                  1. You are entitled to post the Comments on our website and
                  have all necessary licenses and consents to do so;
                </li>
                <li>
                  2. The Comments do not invade any intellectual property right,
                  including without limitation copyright, patent or trademark of
                  any third party;
                </li>
                <li>
                  3. The Comments do not contain any defamatory, libelous,
                  offensive, indecent or otherwise unlawful material which is an
                  invasion of privacy
                </li>
                <li>
                  4. The Comments will not be used to solicit or promote
                  business or custom or present commercial activities or
                  unlawful activity.
                </li>
              </ul>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                You hereby grant HalalBaqqal a non-exclusive license to use,
                reproduce, edit and authorize others to use, reproduce and edit
                any of your Comments in any and all forms, formats or media.
              </p>

              <h3>Hyperlinking to our Content</h3>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                The following organizations may link to our Website without
                prior written approval:
              </p>
              <ul className="mb-5 font-light text-gray-500 dark:text-gray-400">
                <li>1. Government agencies;</li>
                <li>2. Search engines;</li>
                <li>3. News organizations;</li>
                <li>
                  4. Online directory distributors may link to our Website in
                  the same manner as they hyperlink to the Websites of other
                  listed businesses; and
                </li>
                <li>
                  5. System wide Accredited Businesses except soliciting
                  non-profit organizations, charity shopping malls, and charity
                  fundraising groups which may not hyperlink to our Web site.
                </li>
              </ul>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                These organizations may link to our home page, to publications
                or to other Website information so long as the link: (a) is not
                in any way deceptive; (b) does not falsely imply sponsorship,
                endorsement or approval of the linking party and its products
                and/or services; and (c) fits within the context of the linking
                party’s site. We may consider and approve other link requests
                from the following types of organizations:
              </p>
              <ul className="mb-5 font-light text-gray-500 dark:text-gray-400">
                <li>
                  1. Commonly-known consumer and/or business information
                  sources;
                </li>
                <li>2. Dot.com community sites;</li>
                <li>3. Associations or other groups representing charities;</li>
                <li>4. Online directory distributors;</li>
                <li>5. Internet portals;</li>
                <li>6. Accounting, law and consulting firms; and</li>
                <li>7. Educational institutions and trade associations.</li>
              </ul>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                We will approve link requests from these organizations if we
                decide that: (a) the link would not make us look unfavorably to
                ourselves or to our accredited businesses; (b) the organization
                does not have any negative records with us; (c) the benefit to
                us from the visibility of the hyperlink compensates the absence
                of HalalBaqqal; and (d) the link is in the context of general
                resource information.
              </p>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                These organizations may link to our home page so long as the
                link: (a) is not in any way deceptive; (b) does not falsely
                imply sponsorship, endorsement or approval of the linking party
                and its products or services; and (c) fits within the context of
                the linking party’s site.
              </p>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                If you are one of the organizations listed in paragraph 2 above
                and are interested in linking to our website, you must inform us
                by sending an e-mail to HalalBaqqal. Please include your name,
                your organization name, contact information as well as the URL
                of your site, a list of any URLs from which you intend to link
                to our Website, and a list of the URLs on our site to which you
                would like to link. Wait 2-3 weeks for a response. Approved
                organizations may hyperlink to our Website as follows:
              </p>
              <ul className="mb-5 font-light text-gray-500 dark:text-gray-400">
                <li>1. By use of our corporate name; or</li>
                <li>
                  2. By use of the uniform resource locator being linked to; or
                </li>
                <li>
                  3. By use of any other description of our Website being linked
                  to that makes sense within the context and format of content
                  on the linking party’s site.
                </li>
              </ul>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                No use of HalalBaqqal's logo or other artwork will be allowed
                for linking absent a trademark license agreement.
              </p>
              <h3>iFrames</h3>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                Without prior approval and written permission, you may not
                create frames around our Webpages that alter in any way the
                visual presentation or appearance of our Website.
              </p>
              <h3>Content Liability</h3>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                We shall not be hold responsible for any content that appears on
                your Website. You agree to protect and defend us against all
                claims that is rising on your Website. No link(s) should appear
                on any Website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates
                the infringement or other violation of, any third party rights.
                ###
              </p>
              <h2>Reservation of Rights</h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                We reserve the right to request that you remove all links or any
                particular link to our Website. You approve to immediately
                remove all links to our Website upon request. We also reserve
                the right to amen these terms and conditions and it’s linking
                policy at any time. By continuously linking to our Website, you
                agree to be bound to and follow these linking terms and
                conditions.
              </p>
              <h2>Removal of links from our website</h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                If you find any link on our Website that is offensive for any
                reason, you are free to contact and inform us any moment. We
                will consider requests to remove links but we are not obligated
                to or so or to respond to you directly. We do not ensure that
                the information on this website is correct, we do not warrant
                its completeness or accuracy; nor do we promise to ensure that
                the website remains available or that the material on the
                website is kept up to date.
              </p>
              <h2>Disclaimer</h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                The Halal certification is not conducted by HalalBaqqal.
                Therefore the validity of the Halal certification of business
                listed on our website can not be guaranteed by HalalBaqqal
                HalalBaqqal is sourcing information from open soruces. Use of
                information presented at the{" "}
                <a
                  className="hover:text-blue-700"
                  href="https://www.halalbaqqal.com/"
                >
                  www.halalbaqqal.com
                </a>{" "}
                website is at your own risk. HalalBaqqal does not represent any
                of the companies or organization listed on our website. User
                descration is adviced.
              </p>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will:
              </p>
              <ul className="mb-5 font-light text-gray-500 dark:text-gray-400">
                <li>
                  1. limit or exclude our or your liability for death or
                  personal injury;
                </li>
                <li>
                  2. limit or exclude our or your liability for fraud or
                  fraudulent misrepresentation;
                </li>
                <li>
                  3. limit any of our or your liabilities in any way that is not
                  permitted under applicable law; or
                </li>
                <li>
                  4. exclude any of our or your liabilities that may not be
                  excluded under applicable law.
                </li>
              </ul>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer, including liabilities arising in contract,
                in tort and for breach of statutory duty. As long as the website
                and the information and services on the website are provided
                free of charge, we will not be liable for any loss or damage of
                any nature.
              </p>
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <span className="font-medium dark:text-white">
                    HalalBaqqal Team
                  </span>
                </div>
              </div>
            </article>
          </div>
        </section>
      </>
      <Footerr />
    </div>
  );
};

export default Terms;
