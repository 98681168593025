import React from "react";
import Shortlink from "./Shortlink";

function Shortlinkcard() {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Enjoy our free link shortener tool
          </h2>
          <p className="mb-4">
            Whether running social media accounts or creating a digital
            presentation, you can now create short links with our free link
            shortener tool.
          </p>
          <p>
            We are strategists, designers and developers. Innovators and problem
            solvers. Small enough to be simple and quick.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 mt-8">
          <Shortlink />
        </div>
      </div>
    </section>
  );
}

export default Shortlinkcard;
