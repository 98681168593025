import React from "react";
import Blogcard from "./blog/blogcard";
import Navigation from "./reusable/Navigation";
import Footer from "./reusable/Footer";

function Blog() {
  return (
    <div className="App mx-auto container">
      <Navigation />
      <Blogcard />
      <Footer />
    </div>
  );
}

export default Blog;
