import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Blog from "./components/Blog";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Sources from "./components/Sources";
import Blogpost from "./components/blog/Blogpost";
import Bloghalalfest from "./components/blog/Bloghalalfest";
import Businessform from "./components/Businessform";
import HalalMortgage from "./components/blog/Halal-Mortgage";
import Tools from "./components/Tools";
import ReactGA from "react-ga";
import HalalMortgageComparison from "./components/blog/Halal-Mortgage-Comparison";
import BeginnerHalalCryptoGuide from "./components/blog/beginner-halal-crypto-guide.jsx"

const TRACKING_ID = "G-BX38SPNHGL";

ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="blog" element={<Blog />} />
        <Route path="tools" element={<Tools />} />
        <Route path="blogpost" element={<Blogpost />} />
        <Route path="bloghalalfest" element={<Bloghalalfest />} />
        <Route path="businessform" element={<Businessform />} />
        <Route path="halal-mortgage" element={<HalalMortgage />} />
        <Route path="halal-mortgage-comparison" element={<HalalMortgageComparison />} />
        <Route path="beginner-halal-grypto-guide" element={<BeginnerHalalCryptoGuide />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="sources" element={<Sources />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
