import { useState, useMemo, useRef } from "react";
import Cities from "../filters/Cities";
import Resources from "../filters/Resources";
import Ethnicity from "../filters/Ethnic";
import ProvinceState from "../filters/ProvinceState";
import Search from "./Search";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import Card from "./Card";
import Data from "../alberta_mosques.json";
import Discounts from "../filters/Discounts";

export default function Main() {
  const [selectEthnicity, setSelectEthnicity] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedCetegories, setSelectedCategories] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  function handleProvinceChange(event) {
    const userSelection = event.target.value;

    if (event.target.checked) {
      setSelectedProvince([...selectedProvince, userSelection]);
    } else {
      setSelectedProvince(selectedProvince.filter((o) => o !== userSelection));
    }
  }

  function handleChange(event) {
    const userSelection = event.target.value;

    if (event.target.checked) {
      setSelectedOptions([...selectedOptions, userSelection]);
    } else {
      setSelectedOptions(selectedOptions.filter((o) => o !== userSelection));
    }
  }

  function handleCategoriesChange(event) {
    const userSelection = event.target.value;

    if (event.target.checked) {
      setSelectedCategories([...selectedCetegories, userSelection]);
    } else {
      setSelectedCategories(
        selectedCetegories.filter((o) => o !== userSelection)
      );
    }
  }

  function handleEthnicityChange(event) {
    const userSelection = event.target.value;

    if (event.target.checked) {
      setSelectEthnicity((selectEthnicity) => [
        ...selectEthnicity,
        userSelection,
      ]);
    } else {
      setSelectEthnicity((selectEthnicity) =>
        selectEthnicity.filter((o) => o !== userSelection)
      );
    }
  }

  function handleDiscountChange(event) {
    const userSelection = parseInt(event.target.value);

    if (event.target.checked) {
      setSelectedDiscount((selectedDiscount) => [
        ...selectedDiscount,
        userSelection,
      ]);
    } else {
      setSelectedDiscount(selectedDiscount.filter((o) => o !== userSelection));
    }
  }

  function isSelectedDiscount(Discount) {
    if (!selectedDiscount.length) {
      return false;
    }

    return selectedDiscount.includes(Discount);
  }

  function inputValue(event) {
    console.log(event.target.value);
    setSearchTerm(event.target.value);
  }

  function isSelectedProvince(Province) {
    if (!selectedProvince.length) {
      return false;
    }
    return selectedProvince.includes(Province);
  }

  function isChecked(City) {
    if (!selectedOptions.length) {
      return false;
    }
    return selectedOptions.includes(City);
  }

  function isSelectedCategory(Category) {
    if (!selectedCetegories.length) {
      return false;
    }
    return selectedCetegories.includes(Category);
  }

  function isSelectedEthnicity(Ethnicity) {
    if (!selectEthnicity.length) {
      return false;
    }
    return selectEthnicity.includes(Ethnicity);
  }

  function searchValue(userInput) {
    return Data.filter((val) => {
      if (userInput === "") {
        return val;
      } else if (val.Name.toLowerCase().includes(userInput.toLowerCase())) {
        return val;
      }
    });
  }

  const filteredData = useMemo(() => {
    if (
      !selectedOptions.length &&
      !selectedCetegories.length &&
      !selectEthnicity.length &&
      !selectedProvince.length &&
      !selectedDiscount.length &&
      !searchTerm
    ) {
      return Data.slice().sort((a, b) => a.Name.localeCompare(b.Name));
    }
    const filteredData = Data.filter(
      ({ Province, City, Category, Ethnicity, Name, Discount }) => {
        const matchesCity = selectedOptions.length
          ? selectedOptions.includes(City)
          : true;
        const matchesCategory = selectedCetegories.length
          ? selectedCetegories.includes(Category)
          : true;
        const matchesEthnicity = selectEthnicity.length
          ? selectEthnicity.includes(Ethnicity)
          : true;
        const matchesProvince = selectedProvince.length
          ? selectedProvince.includes(Province)
          : true;
        const matchesSearchTerm = searchTerm
          ? Name.toLowerCase().includes(searchTerm.toLowerCase())
          : true;
        const matchesDiscount = selectedDiscount.length
          ? selectedDiscount.includes(Discount)
          : true;
        return (
          matchesCity &&
          matchesCategory &&
          matchesEthnicity &&
          matchesProvince &&
          matchesSearchTerm &&
          matchesDiscount
        );
      }
    );

    const sorted = filteredData
      .slice()
      .sort((a, b) => a.Name.localeCompare(b.Name));
    return sorted;
  }, [
    selectedOptions,
    selectedCetegories,
    selectEthnicity,
    selectedProvince,
    selectedDiscount,
    searchTerm,
  ]);

  const cache = useRef(
    new CellMeasurerCache({ fixedWidth: true, defaultHeight: 300 })
  );

  return (
    <>
      <div className="container">
        <div className="mx-auto mb-5" style={{ width: "80%" }}>
          <Search inputValue={inputValue} searchValue={searchValue} />
        </div>

        <div
          className="mx-auto grid grid-cols-1 gap-x-2 gap-y-2 md:grid-cols-3 md:gap-x-4 md:gap-y-4 lg:grid-cols-5 lg:gap-x-4 lg:gap-y-4"
          style={{ width: "80%", justifyItems: "center" }}
        >
          <Resources
            handleChange={handleCategoriesChange} //business category chnage handle
            isSelected={isSelectedCategory} //category checkmark by user
            selectedOptions={selectedCetegories}
            style={{ width: "30px", alignItems: "center" }}
          />

          <ProvinceState
            handleChange={handleProvinceChange}
            isSelected={isSelectedProvince}
            selectedOptions={selectedProvince}
          />

          <Cities
            handleChange={handleChange} //city change handle
            isChecked={isChecked} //city checkmark by user
            selectedOptions={selectedOptions}
            selectedProvince={selectedProvince}
          />

          <Ethnicity
            handleChange={handleEthnicityChange} //ethnicity category chnage handle
            isSelected={isSelectedEthnicity} //ethnicity checkmark by user
            selectedOptions={selectEthnicity}
          />

          <Discounts
            handleChange={handleDiscountChange}
            isSelected={isSelectedDiscount}
            selectedOptions={selectedDiscount}
          />
        </div>

        <div className="mx-auto" style={{ width: "80%", height: "100vh" }}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={430}
                deferredMeasurementCache={cache.current}
                rowCount={filteredData.length}
                rowRenderer={({ key, index, style, parent }) => {
                  const info = filteredData[index];
                  return (
                    <div style={style} key={key}>
                      <CellMeasurer
                        cache={cache.current}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}
                      >
                        <Card
                          Googlelink={info.Googlelink}
                          Name={info.Name}
                          Moreinfo={info.Moreinfo}
                          hasDiscount={info.hasDiscount}
                          Discount={info.Discount}
                          Address={info.Address}
                          Phones={info.Phones}
                          Email={info.Email}
                          Website={info.Website}
                          Reviewcount={info.Reviewcount}
                          Rating={info.Rating}
                          className="Card"
                          style={{
                            height: "auto",
                          }}
                        />
                      </CellMeasurer>
                    </div>
                  );
                }}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    </>
  );
}
