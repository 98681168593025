import React from "react";
import ZakatCalculator from "./ZakatCalculator";

function Zakatcard() {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Make sure that you pay right amount of Zakat.
          </h2>
          <p className="mb-4">
            It's easier than you may imagine to calculate your Zakat. We have
            discovered that categorizing your assets simplifies the zakat
            calculating process.
          </p>
          <p>
            Once you have identified the value of your net assets, you can
            calculate the amount of Zakat you must pay. The next step is
            determining if your net assets are more than or equal to the Nisab
            requirement.
          </p>
        </div>
        <div className="flex justify-center">
          <ZakatCalculator />
        </div>
      </div>
    </section>
  );
}

export default Zakatcard;
