import React from "react";
import Navigation from "../reusable/Navigation";
import Footer from "../reusable/Footer";


import Logo from "../assets/logo/1.png";
import HalalLogo from "../assets/blogs/mortgage/halal.webp";
import HomeIcon from "../assets/blogs/mortgage/house.webp";

function HalalMortgage() {
  return (
    <div className="App mx-auto container">
      <Navigation />
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
              Halal Mortgage and Financing
            </h2>
            <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
              First Time Home Buyers Guide
            </p>
          </div>
        </div>
      </section>
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 flex flex-wrap">
        <div className="px-4 mx-auto max-w-screen-xl">
          <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
            <header className="mb-4 lg:mb-6 not-format">
              <address className="flex items-center mb-6 not-italic">
                <div className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                  <img
                    className="mr-4 w-16 h-16 rounded-full"
                    src={Logo}
                    alt="halal baqqal logo"
                  />
                  <div>
                    <a
                      href="test.html"
                      rel="author"
                      className="text-xl font-bold text-gray-900 dark:text-white"
                    >
                      Halal Baqqal team
                    </a>

                    <p className="text-base font-light text-gray-500 dark:text-gray-400">
                      <time dateTime="2023-02-27" title="February 27th, 2023">
                        July 13, 2023
                      </time>
                    </p>
                  </div>
                </div>
              </address>
            </header>
            <p className="lead">Assalamu Alaikum,</p>
            <p>
              Are you dreaming about owning your first home but needing help
              navigating interest-free Islamic mortgages?
            </p>
            <p>
              This article will help you understand the costs associated with
              Islamic mortgages. You will learn about agreement types,
              qualifications, processes, and the authenticity of the Islamic
              finance model itself.
            </p>
            <div className="flex ">
              <figure className="mr-10">
                <img src={HalalLogo} alt="halal logo" width="250" />
              </figure>
              <figure>
                <img src={HomeIcon} alt="halal logo" width="250" />
              </figure>
            </div>

            <p>
              Please note that the information provided in this article is for
              educational purposes only. Do your research and consult with
              licensed financial advisors.
            </p>
            <h3 id="financing">Types of Islamic Halal Financing</h3>
            <p>
              There are three types of agreements in Islamic Halal financing:
            </p>
            <ul>
              <li>Murabaha</li>
              <li>Ijara </li>
              <li>Musharaka</li>
            </ul>
            <h3>Murabaha</h3>
            <p>
              Under a typical Murabaha agreement, the financier buys the
              property and immediately sells it to you (the customer) at a
              higher price, including a profit. The amount of profit is based on
              several factors, including your risk profile, credit history,
              deposit amount, property value, and repayment term.
            </p>
            <p>
              Instead of owning the property as an individual, you hold the
              property title within a corporation you set up for this purpose.
              Unfortunately, this makes you ineligible for some provinces' land
              transfer tax rebates available to first-time home buyers.
            </p>
            <p>
              There are, however, variations of Murabaha available in Canada,
              where the borrower assumes ownership of the property directly
              without having to take the step of purchasing it through their
              lender.{" "}
            </p>
            <h3>Ijara</h3>
            <p>
              An Ijara mortgage is similar to a rent-to-own scheme. The
              financier buys the property outright and rents it to you for a
              fixed term. Over this term, you make regular steady payments,
              which are a combination of rent, repayment of capital, and profit
              for the financier. Once the agreement is over, ownership is
              transferred to you.
            </p>
            <h3>Musharaka</h3>
            <p>
              With a Musharaka mortgage agreement, you and the financier jointly
              own the property. Your payments are a combination of rent of the
              financier's portion of the property and property purchase payment
              to buy a little bit of the financier's share until, eventually, at
              the end of the contract term, you own the whole property.
            </p>
            <p>
              Like typical Western mortgages, halal mortgages offer a variety of
              terms, conditions, and fees. Some Murabaha agreements, for
              example, do not allow for additional payments, while some
              Musharaka mortgage customers can pay up to 20% extra per year
              without penalty.
            </p>
            <p>
              It's crucial to know the details of any halal mortgage product and
              evaluate your options based on your needs and financial situation.
            </p>
            <h3 id="fincost">The Cost of Islamic Home Financing</h3>
            <p>
              You may have already made a few key decisions: where you want to
              buy your home or the type of home you are searching for. What you
              may need is more Guidance on how to apply for financing.
            </p>
            <p>
              You must know that an "Islamic mortgage" isn't a mortgage. It is
              an entirely different model of financing based on the concepts of
              partnership and co-ownership. While the structure is completely
              different from a traditional mortgage, however, the costs in the
              US are comparable. In Canada, though, the prices are higher.
            </p>
            <h3 id="downpayment">Down Payment</h3>
            <p>
              The down payment is typically the most significant expense on a
              buyer's mind. The cost of a down payment will vary depending on
              various factors, the most important being the home's price and the
              amount you can spend.
            </p>
            <p>
              If you can afford a minimum of 20% of the home value as a down
              payment, you will get a better rate on your monthly expenses. Some
              organizations, such as Guidance Residential in the US, require
              only 3% as a down payment of the home value. But unfortunately,
              there is nothing even close in Canada. At least, that I know of.
            </p>
            <p>
              An industry term to know here is the Finance to Value (FTV) ratio:
              The amount you need to finance compared to the home's value. If
              your FTV is 90%, you'll pay 10% of the home purchase value as the
              down payment. If your FTV is lower, say 70%, you'll need 30% of
              the home purchase price for the down payment. In general, the
              lower the FTV, the cheaper your rate for monthly payments, as it
              is less risky for the financier.
            </p>
            <h3 id="closing">Closing Costs</h3>
            <p>
              Closing expenses must also be taken into account. Closing costs
              are extra charges like the cost of the attorney, escrow fees,
              appraisal fees, finance fees, and utility changes. A decent
              generalization is that closing expenses will be covered by 2% of
              the purchase price. On a $200,000 house with a 5% down payment,
              you would need $14,000 for downpayment and closing expenses. But
              there are other upfront expenses in addition to the down payment.
            </p>
            <h3 id="payments">Monthly Payments</h3>
            <p>
              Your monthly expenses remain after those initial down payments and
              closing costs are completed. While the payments have been
              structured to resemble mortgage payments, the foundation is
              entirely different.
            </p>
            <p>
              When you finance a home through a halal financing institution, you
              are not a borrower but rather a co-owner of the property with a
              halal financing institution. This means you will not be repaying a
              loan at interest; you are buying a larger stake in a co-owned
              property. In other words, your monthly payments are your means of
              gradually buying a halal financing institution's share of the
              property.
            </p>
            <p>
              For example, let's say you choose a home priced to sell at
              $100,000 and pay 5% (or $5,000), while financial institution
              provides the remaining 95% (or $95,000). You are 5% owner, and the
              halal financing institution is 95% owner. You will then gradually
              buy that 95% from a financial institution through monthly,
              competitive payments with a traditional mortgage.
            </p>
            <h3 id="ribafree">Riba-Free Financing</h3>
            <p>
              Financial institutions' financing must be certified to be 100%
              free of riba or interest.
            </p>
            <p>
              The customer's monthly payment amount consists of two elements
              that are combined into one. Those two parts are a profit payment
              and an acquisition payment.
            </p>
            <p>
              The home buyer and the halal financing institution would be
              entitled to utilize the property as co-owners, but the halal
              financing institution grants the client the sole use and pleasure
              of the entire property. The customer must pay a profit to the
              halal financing institution for this exclusive right. This profit
              payout is based on a competitive rate of return applied to the
              ownership stake held by a halal financing institution. The usual
              monthly payment the consumer will be required to make includes
              this fee.
            </p>
            <p>
              The balance of the monthly payment, known as the acquisition
              payment, enables the consumer to purchase a larger and larger
              percentage of the home.
            </p>
            <p>
              Together, these two amounts make up a predictable monthly amount
              that is competitive with the monthly payments offered in a
              conventional mortgage. But only in the US for now. In Canada, the
              market conditions are different. The halal financing institution's
              ownership share decreases as the customer makes acquisition
              payments to acquire a further stake in the property. This creates
              the declining balance nature of the program.
            </p>
            <h3 id="calculations">How Monthly Payments Are Calculated</h3>
            <p>
              American and Canadian homebuyers expect their monthly payments to
              be competitive with those of a traditional loan. As a result,
              halal financing companies work to match their rates with market
              rates. As a result, the statistics appear more familiar, making it
              simpler for buyers to weigh their options. However, despite their
              apparent similarities, the monthly payments are not a loan with
              interest being repaid.
            </p>
            <h3>
              Is it true that Islamic financing costs more than a conventional
              home loan?
            </h3>
            <p>
              It depends. In the US, It was once true that Islamic financing was
              more expensive than a traditional mortgage, but this is no longer
              the case. As Islamic home financing has become more widespread,
              this enabled costs to be reduced. The prices are now competitive
              with a conventional mortgage, while the risks to the buyer are
              lower with Islamic home financing.
            </p>
            <p>Unfortunately, this is still the case in Canada.</p>
            <h3 id="expenses">How much will your Islamic mortgage cost?</h3>
            <p>
              The value of the home you choose to purchase will determine how
              much of that price you will have to pay for a halal financing
              institution to purchase on your behalf. The other important
              consideration is the rate you can obtain, which, as was already
              discussed, must be competitive with current market rates. Your
              credit score is also impacted by cost.
            </p>
            <p>
              So the question comes back to how much home you can afford. When
              buyers visit a halal home financing provider, the conversation
              typically starts with two key questions:
            </p>
            <ul>
              <li>"Can you afford to pay for this?"</li>
              <li>"What are your other monthly expenses?"</li>
            </ul>
            <p>
              The information buyers provide to answer these questions helps
              determine their income and debt ratios. These ratios are important
              because they shed light on the buyers' overall qualifications
              rather than solely their cash in hand.
            </p>
            <p>
              With the information you provide, the financing provider will
              calculate two ratios:
            </p>
            <p>
              1. Front-end ratio—housing expenses (including taxes and
              homeowner's insurance) over income. If a buyer has a gross income
              of $6,000 per month and housing expenses of $1,500, their
              front-end ratio is $1,500 / $5,000, or 25 percent.
            </p>
            <p>
              2. Back-end ratio—this incorporates additional debt. If that same
              buyer has a car payment and student debt of an additional $500 per
              month, that will make their back-end ratio $2000 / $6,000, or 33
              percent.
            </p>
            <p>
              Your Account Executive can assist you in determining the amount of
              funding you are eligible for and the rate you can receive using
              the information you provide.
            </p>
            <p>
              You can find a quick estimate online using halal financing
              institution calculators, but your Account Executive would be happy
              to delve deeper and answer your questions.
            </p>
            <h3 id="funding">How can I come up with these funds?</h3>
            <p>
              These expenses can look daunting, so here are some general
              money-saving tips to help:
            </p>
            <p>
              Track your expenses for a month and look for places to trim Set
              savings goals or, better yet, "pay yourself first" — put the
              savings aside first after receiving each paycheck Plan to avoid
              last-minute expenses like lunches out If you're fortunate enough
              to have someone willing to help with a cash gift, ask your Account
              Executive about the documentation you'll need.
            </p>
            <p>
              So there you have it! A comprehensive guide to halal Islamic
              mortgages for first-time home buyers. This information helps you
              make an informed decision about the process and clarifies your
              homeownership path. Remember, owning a home is a major decision,
              and it's essential that you understand the details, the costs, and
              the benefits before you sign anything. As with anything in life,
              you need to do your homework. Read up, ask many questions, consult
              with professionals, and never rush into a decision.
            </p>
            <p>
              This article is also a starting point - a tool to help you begin
              your journey. Always consult licensed financial advisors and
              ensure that any financial product you choose aligns with your
              values, lifestyle, and financial goals.
            </p>
            <p>
              And lastly, remember this - everyone's financial situation is
              unique. The best decision is the one that's right for you, your
              circumstances, and your future.
            </p>
            <p>
              Thank you so much for joining me today. If you found this article
              helpful, please give it a thumbs up, share it with anyone who
              might find it useful, and remember to follow us on{" "}
              <a
                href="https://twitter.com/HalalBaqqal"
                className="text-xl font-semibold"
              >
                Twitter
              </a>{" "}
              and{" "}
              <a
                href="https://www.tiktok.com/@halalbaqqal"
                className="text-xl font-semibold"
              >
                TikTok
              </a>{" "}
              to stay up-to-date.
            </p>
            <p>
              May your journey to homeownership be smooth and successful,
              Assalamu Alaikum.
            </p>
          </article>
        </div>
        <div className="sideBar">
          <div class="ml-5 mr-5 sticky top-0 h-96">
            <h3 className="font-semibold mt-10 ml-4 text-xl">CONTENT</h3>
            <ul className="w-64 h-96 p-5 rounded shadow-lg leading-loose">
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#financing">Type of Financing</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#fincost">The Cost of Financing</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#downpayment">Down Payment</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#closing">Closing Costs</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#payments">Monthly Payments</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#ribafree">Riba-Free Financing</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#calculations">Financing Calculation</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#expenses">Financing Cost</a>
              </li>
              <li className="hover:text-blue-700 list-disc ml-3">
                <a href="#funding">Financing Funding</a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default HalalMortgage;
