import { Dropdown } from "flowbite-react";
import Category from "../alberta_mosques.json";

export default function Cities(props) {
  const categories = Array.from(
    new Set(Category.map((value) => value.Category))
  );

  categories.sort();

  return (
    <Dropdown
      label="Category"
      className="overflow-y-auto h-48"
      style={{ width: 175 }}
    >
      {categories.map((category, index) => (
        <div className="w-42" key={index}>
          <Dropdown.Item>
            <input
              // id="categories-checkbox"
              aria-label="checkbox"
              type="checkbox"
              value={category}
              checked={props.selectedOptions.includes(category)}
              onChange={props.handleChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <label
              // htmlFor="calgary-checkbox"
              className="w-full ml-2 text-lg font-medium text-gray-900 dark:text-gray-300"
            >
              {category}
            </label>
          </Dropdown.Item>
        </div>
      ))}
    </Dropdown>
  );
}
