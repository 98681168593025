import React from "react";
import Navigation from "./reusable/Navigation";
import Footerr from "./reusable/Footer";
import Halalb from "./assets/about/halalb.webp";
// import Popup from "./popuponhover/PopuponHover"

export default function About() {
  return (
    <div className="App container mx-auto test">
      <Navigation />
      {/* <Popup/> */}
      <>
        <section className="bg-white dark:bg-gray-900">
          <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
                Information portal of muslim community
              </h1>
              <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                We cover information for spiritual growth and local community
                events to business opportunities.
              </p>
              <a
                href="mailto:info@halalbaqqal.com"
                className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center bg-blue-700 text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
              >
                Contact Us
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
            <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
              <img src={Halalb} alt="mosque" />
            </div>
          </div>
        </section>
        <section className="bg-white dark:bg-gray-900">
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="max-w-screen-xlg text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900 dark:text-white">
                Empowering muslim community of North America
              </h2>
              <p className="mb-4 font-light">
                Welcome to <strong className="font-bold">Halal Baqqal</strong>{" "}
                information portal!
              </p>
              <p className="mb-4 font-light">
                We are a one-stop destination for all your information needs on
                Halal topics. Our website is designed to provide you with
                information on a wide range of topics. We strive to make the
                information easily accessible and user-friendly. Our team works
                tirelessly to ensure that the information provided on our portal
                is accurate and reliable.
              </p>
              <p className="mb-4 font-light">
                We cover a variety of topics including, but not limited to,
                halal stores(markets, e-commerce, groceries etc.), religious
                places, current events, science, technology, health, business,
                and entertainment. We are committed to providing our users with
                a diverse range of information that will keep them informed and
                educated.
              </p>
              <p className="mb-4 font-light">
                In addition to providing information, our portal also will offer
                a range of interactive features, such as forums and quizzes,
                that allow users to engage with the content and with other
                users. We shall also have a section dedicated to user-generated
                content, where users can share their own experiences and
                insights on various topics.
              </p>
              <p className="mb-4 font-light">
                We take privacy and security seriously. We have implemented
                several measures to ensure that our users' personal information
                is protected and secure.
              </p>
              <p className="mb-4 font-light">
                Thank you for visiting our information portal. Our goal is to
                make this portal informative and enjoyable. We welcome your
                feedback and suggestions. Contact us if you have something to
                say or just ask questions.
              </p>
            </div>
          </div>
        </section>
      </>
      <Footerr />
    </div>
  );
}
