import { Dropdown } from "flowbite-react";

export default function Ethnic(props) {
  const ethnicities = [
    "Afghan",
    "African",
    "American",
    "Azerbaijani",
    "Indian",
    "Mediterranean",
    "Italian",
    "Turkish",
    "Persian",
    "Lebanese",
    "Chinese",
    "Syrian",
    "Mexican",
    "Arab",
    "Portuguese",
    "Pakistani",
    "Yemenite",
    "Nepalese",
    "Jamaican",
    "Caribbean",
    "Korean",
    "Vietnamese",
    "Japanese",
    "Canadian",
    "Pakistani",
    "Middle Eastern",
    "Uyghur",
    "Kurdish",
    "Moroccan",
    "Ethiopian",
    "Greek",
    "Egyptian",
    "Bangladeshi",
    "Thai",
    "Malaysian",
    "Uzbeki",
    "Somali",
    "Indonesian",
    "Algerian",
    "Tunisian",
  ];

  ethnicities.sort();

  return (
    <Dropdown
      label="Type"
      className="overflow-y-auto h-48"
      style={{ width: 175 }}
    >
      {ethnicities.map((ethnicity, key) => (
        <div className="w-40" key={key}>
          <Dropdown.Item>
            <input
              // id="cities-checkbox"
              aria-label="checkbox"
              type="checkbox"
              value={ethnicity}
              checked={props.selectedOptions?.includes(ethnicity)}
              onChange={props.handleChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <label
              // htmlFor="calgary-checkbox"
              className="w-full ml-2 text-lg font-medium text-gray-900 dark:text-gray-300"
            >
              {ethnicity}
            </label>
          </Dropdown.Item>
        </div>
      ))}
    </Dropdown>
  );
}
