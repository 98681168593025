import React from "react";
import Navigation from "../reusable/Navigation";

import Footer from "../reusable/Footer";
import Logo from "../assets/logo/1.png";

function Blogpost() {
  return (
    <div className="App mx-auto container">
      <Navigation />
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 dark:text-white">
              Halal Baqqal - Muslims go to information portal
            </h2>
            <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
              Building united digital platform for Muslims.
            </p>
          </div>
        </div>
      </section>
      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
          <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
            <header className="mb-4 lg:mb-6 not-format">
              <address className="flex items-center mb-6 not-italic">
                <div className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                  <img
                    className="mr-4 w-16 h-16 rounded-full"
                    src={Logo}
                    alt="halal baqqal logo"
                  />
                  <div>
                    <a
                      href="test.html"
                      rel="author"
                      className="text-xl font-bold text-gray-900 dark:text-white"
                    >
                      Halla Baqqal team
                    </a>
                    <p className="text-base font-light text-gray-500 dark:text-gray-400">
                      Halal Baqqal
                    </p>
                    <p className="text-base font-light text-gray-500 dark:text-gray-400">
                      <time dateTime="2023-02-27" title="February 27th, 2023">
                        Feb. 27, 2023
                      </time>
                    </p>
                  </div>
                </div>
              </address>
            </header>
            <p className="lead">Assalamu Alaikum,</p>
            <p>
              Welcome to our Muslim website/portal, which provides valuable
              information about halal restaurants, stores, businesses,
              financing, certification, Muslim technology, science, startups,
              and religious and cultural events in North America (Canada and
              USA). We aim to make this website available to everyone and
              provide a one-stop shop for all things halal and Muslim.
            </p>
            <p>
              We acknowledge the importance of being able to find halal
              restaurants, stores, and businesses, especially in a foreign
              country where it can be challenging to navigate. That's why our
              website features a comprehensive database of halal businesses and
              services, making informed choices when purchasing products and
              services easier. We strive to ensure that all information on our
              website is accurate, up-to-date, and reliable, so you can trust
              the information you find here.
            </p>
            <figure>
              <img src={Logo} alt="halal baqqal logo" />
              <figcaption>Halal Baqqal branded logo</figcaption>
            </figure>

            <p>
              In addition to halal businesses, we also feature Muslim technology
              and science. We believe that technology and science play a
              critical role in the growth and prosperity of the Muslim
              community. Our website will include information about Muslim tech
              startups, cutting-edge technologies developed by Muslim
              innovators, and the latest scientific breakthroughs that
              positively impact the Muslim community.
            </p>
            <p>
              Our website will be a valuable resource for those seeking
              information about Eid celebrations, Islamic conferences, cultural
              festivals, and other events relevant to the Muslim community. We
              will also feature information about Ramadan, including prayer
              times, iftar schedules, and tips for fasting. We are also
              committed to providing information about Islamic and cultural
              events in North America.
            </p>
            <p>
              One of the unique features of our website is that we also provide
              information about halal financing and investment opportunities. We
              understand that many Muslims are interested in investing their
              money in businesses and ventures that align with their faith. Our
              website will provide information about halal investment options,
              Sharia-compliant financing, and ethical investment opportunities.
            </p>
            <p>
              We believe our website will be a valuable resource for the Muslim
              community in North America. We are committed to providing a
              user-friendly platform that makes it easy to navigate and find the
              information you need quickly. By providing a centralized hub of
              information about halal businesses, Muslim technology and science,
              investment opportunities, and cultural events, we aim to
              accelerate the prosperity of the Muslim community in North
              America.
            </p>
            <p>
              Our experts are dedicated to providing valuable information on
              halal financing, investment opportunities, and certification
              processes, empowering you to make sound financial decisions that
              align with your faith. We also strive to create a community where
              Muslims can connect, share ideas, and support one another,
              building strong social ties that foster growth and prosperity.
            </p>
            <p>
              We understand that the Muslim community in North America is
              diverse, and each community has unique needs and challenges.
              That's why we are committed to providing relevant and valuable
              information to all Muslims living in North America. No matter your
              occupation a student, a professional, a business owner, or a
              homemaker, our website will have something valuable for you.
            </p>
            <p>
              In conclusion, our Muslim website/portal is a one-stop shop for
              halal and Muslims. We are committed to providing a user-friendly
              platform that makes it easy to navigate and find the information
              you need quickly. We believe that by providing information about
              halal businesses, Muslim technology and science, investment
              opportunities, cultural events, and more, we can accelerate the
              prosperity of the Muslim community in North America. We thank you
              for visiting our website, and we hope you find the information you
              need to support your spiritual, social, and economic growth.
            </p>
            <p>Jazakallah Khair.</p>
          </article>
        </div>
      </main>
      {/* <aside
        aria-label="Related articles"
        className="py-8 lg:py-24 bg-gray-50 dark:bg-gray-800"
      >
        <div className="px-4 mx-auto max-w-screen-xl">
          <h2 className="mb-8 text-2xl font-bold text-gray-900 dark:text-white">
            Related articles
          </h2>
          <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
            <article className="max-w-xs">
              <a href="test.html">
                <img
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/article/blog-1.png"
                  className="mb-5 rounded-lg"
                  alt="Image1"
                />
              </a>
              <h2 className="mb-2 text-xl font-bold leading-tight text-gray-900 dark:text-white">
                <a href="test.html">Our first office</a>
              </h2>
              <p className="mb-4 font-light text-gray-500 dark:text-gray-400">
                Over the past year, Volosoft has undergone many changes! After
                months of preparation.
              </p>
              <a
                href="test.html"
                className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 dark:text-primary-500 hover:no-underline"
              >
                Read in 2 minutes
              </a>
            </article>
            <article className="max-w-xs">
              <a href="test.html">
                <img
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/article/blog-2.png"
                  className="mb-5 rounded-lg"
                  alt="Image2"
                />
              </a>
              <h2 className="mb-2 text-xl font-bold leading-tight text-gray-900 dark:text-white">
                <a href="test.html">Enterprise design tips</a>
              </h2>
              <p className="mb-4 font-light text-gray-500 dark:text-gray-400">
                Over the past year, Volosoft has undergone many changes! After
                months of preparation.
              </p>
              <a
                href="test.html"
                className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 dark:text-primary-500 hover:no-underline"
              >
                Read in 12 minutes
              </a>
            </article>
            <article className="max-w-xs">
              <a href="test.html">
                <img
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/article/blog-3.png"
                  className="mb-5 rounded-lg"
                  alt="Image3"
                />
              </a>
              <h2 className="mb-2 text-xl font-bold leading-tight text-gray-900 dark:text-white">
                <a href="test.html">We partnered with Google</a>
              </h2>
              <p className="mb-4 font-light text-gray-500 dark:text-gray-400">
                Over the past year, Volosoft has undergone many changes! After
                months of preparation.
              </p>
              <a
                href="test.html"
                className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 dark:text-primary-500 hover:no-underline"
              >
                Read in 8 minutes
              </a>
            </article>
            <article className="max-w-xs">
              <a href="test.html">
                <img
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/article/blog-4.png"
                  className="mb-5 rounded-lg"
                  alt="Image4"
                />
              </a>
              <h2 className="mb-2 text-xl font-bold leading-tight text-gray-900 dark:text-white">
                <a href="test.html">Our first project with React</a>
              </h2>
              <p className="mb-4 font-light text-gray-500 dark:text-gray-400">
                Over the past year, Volosoft has undergone many changes! After
                months of preparation.
              </p>
              <a
                href="test.html"
                className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 dark:text-primary-500 hover:no-underline"
              >
                Read in 4 minutes
              </a>
            </article>
          </div>
        </div>
      </aside> */}
      <Footer />
    </div>
  );
}

export default Blogpost;
