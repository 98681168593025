import React, { useState, useEffect } from "react";
import jsonData from "../alberta_mosques.json";

function DataStat() {
  const [totalObjects, setTotalObjects] = useState(0);
  const [categoryCounts, setCategoryCounts] = useState({});

  useEffect(() => {
    let count = 0;
    let categories = {};
    jsonData.forEach((obj) => {
      count++;
      if (obj.Category in categories) {
        categories[obj.Category]++;
      } else {
        categories[obj.Category] = 1;
      }
    });

    // Convert the object into an array of key-value pairs
    const categoryCountsArray = Object.entries(categories);

    // Sort the array based on the value in descending order
    categoryCountsArray.sort((a, b) => b[1] - a[1]);

    // Get the top 4 categories and calculate the total count
    let top4CategoriesArray = categoryCountsArray.slice(0, 4);
    let top4CategoryCount = top4CategoriesArray.reduce(
      (acc, categoryCount) => acc + categoryCount[1],
      0
    );

    // Calculate the counts for the "Others" category
    let othersCount = 0;
    for (let i = 4; i < categoryCountsArray.length; i++) {
      othersCount += categoryCountsArray[i][1];
    }

    // Map the array back to an object and add the "Others" category
    const categoryCounts = Object.fromEntries([
      ...top4CategoriesArray,
      ["Others", othersCount],
    ]);

    setTotalObjects(count);
    setCategoryCounts(categoryCounts);
  }, []);

  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
          <h2 className="mb-8 lg:mb-16 text-5xl font-extrabold tracking-tight leading-tight text-center text-gray-900 dark:text-white md:text-4xl">
            Add your business. You’ll be in good company.
          </h2>
          <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6 dark:text-gray-400">
            <h2 className="flex flex-col justify-center items-center text-xl font-semibold text-gray-900">
              <span>Total Entities:</span>
              <span>{totalObjects}</span>
            </h2>
            {Object.keys(categoryCounts).map((category) => (
              <span className="flex justify-center items-center" key={category}>
                <h2 className="flex flex-col justify-center items-center text-xl font-semibold text-gray-900">
                  <span>
                    {category === "Others" ? category : category + "s"}:
                  </span>
                  <span>{categoryCounts[category]}</span>
                </h2>
              </span>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default DataStat;
