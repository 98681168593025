import { Dropdown } from "flowbite-react";
import Locations from "../alberta_mosques.json";

export default function Cities(props) {
  const citiesInSelectedProvince = Array.from(
    new Set(
      Locations.filter((item) => {
        if (!props.selectedProvince || props.selectedProvince.length === 0) {
          // If no province is selected, include all cities
          return true;
        } else {
          return props.selectedProvince.includes(item.Province);
        }
      }).map((item) => item.City)
    )
  );
  citiesInSelectedProvince.sort();

  return (
    <Dropdown
      label="City"
      className="overflow-y-auto h-48"
      style={{ width: 175 }}
    >
      {citiesInSelectedProvince.map((city, index) => (
        <div className="w-40" key={index}>
          <Dropdown.Item>
            <input
              // id="cities-checkbox"
              aria-label="checkbox"
              type="checkbox"
              value={city}
              checked={props.selectedOptions.includes(city)}
              onChange={props.handleChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <label
              // htmlFor="calgary-checkbox"
              className="w-full ml-2 text-lg font-medium text-gray-900 dark:text-gray-300"
            >
              {city}
            </label>
          </Dropdown.Item>
        </div>
      ))}
    </Dropdown>
  );
}
