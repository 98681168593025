import React from "react";
import ShareIcon from "../icons/ShareIcon";

function Card({
  Googlelink,
  Name,
  Moreinfo,
  Address,
  Phones,
  Email,
  Website,
  Reviewcount,
  Rating,
  style,
  hasDiscount,
  Discount,
  className = "",
}) {
  return (
    <div
      style={style}
      className={`bg-white p-4 rounded-lg shadow-xl mb-10 flex flex-wrap mx-auto${className}`}
    >
      <div className="w-4/5">
        <h2 className="mb-2 text-2xl hover:text-indigo-700 font-bold">
          <a
            href={Googlelink}
            className="underline decoration-indigo-500"
            rel="noreferrer"
            target="_blank"
          >
            {Name}
          </a>
        </h2>
        <div className="flex">
          <p className="text-md text-justify text-blue-500">
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              <a href={Moreinfo} rel="noreferrer" target="_blank">
                More Info
              </a>
            </button>
          </p>
          <p className="text-md text-justify text-blue-500">
            {hasDiscount === true && (
              <button
                type="button"
                className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={
                    "mailto:" +
                    Email +
                    "?subject=Halal Baqqal - Request for Discounted Services&body=Dear " +
                    Name +
                    "I came across your discount for your product/service. Please assist me to benefit from it. Thank you in advance."
                  }
                >
                  Grab {Discount}% Discount
                </a>
              </button>
            )}
          </p>
        </div>

        <div className="flex flex-wrap  flex-col mt-auto pt-3 text-md">
          <p className="mr-2 mb-2">{Address}</p>
          <p className="mr-2 mb-2">{Phones}</p>
          <p className="mr-2 mb-2">{Email}</p>
          <p className="mr-2 mb-2">
            <a href={Website} rel="noreferrer" target="_blank">
              {Website}
            </a>
          </p>
        </div>
        <div className="mb-4 flex flex-wrap flex-col">
          <span className="mr-2 mt-2">Reviews: {Reviewcount}</span>
          <span className="mr-2">Ratings: {Rating}</span>
        </div>
      </div>
      <div className="w-1/5 flex sm:flex-wrap items-end pb-5 ">
        <ShareIcon />
      </div>
    </div>
  );
}

export default Card;
