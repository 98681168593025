import { Footer } from "flowbite-react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo/1.png";

export default function Footerr() {
  return (
    <Footer container={true}>
      <div className="w-full">
        <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
          <div>
            <Footer.Brand href="https://halalbaqqal.com/">
              <img
                src={Logo}
                className="mr-3 h-14 sm:h-18"
                alt="Halal Baqqal Logo"
              />
              <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                Halal Baqqal
              </span>
            </Footer.Brand>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
            <div>
              <Footer.Title title="about" />
              <Footer.LinkGroup col={true}>
                <Link to="/">HalalBaqqal</Link>
                <Link to="/about">About</Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Follow us" />
              <Footer.LinkGroup col={true}>
                <Footer.Link href="https://www.tiktok.com/@halalbaqqal">
                  TikTok
                </Footer.Link>
                <Footer.Link href="https://twitter.com/HalalBaqqal">
                  Twitter
                </Footer.Link>
                <Footer.Link href="https://www.facebook.com/halalbaqqal">
                  Facebook
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Legal" />
              <Footer.LinkGroup col={true}>
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="/terms">Terms & Conditions</Link>
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright href="#" by="HalalBaqqal" year={2023} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            Designed and developed by{" "}
            <a
              href="https://www.viteconsulting.com/"
              className="font-semibold underline decoration-pink-500"
            >
              {" "}
              Vite Consulting
            </a>
          </div>
        </div>
      </div>
    </Footer>
  );
}
