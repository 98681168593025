import React from "react";
import Sources from "../sources/books.json";
import esources from "../sources/esources.json";

function Sourcecard() {
  return (
    <>
      <div className="flex flex-col bg-white m-auto p-auto">
        <h1 className="flex py-5  md:px-10 px-5 mx-5 font-bold text-4xl text-gray-800">
          Must-read books for Muslims
        </h1>
        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
          <div className="flex flex-nowrap  md:ml-20 ml-40">
            {Sources.map((val, key) => (
              <div className="inline-block" key={key}>
                <div className="flex justify-center w-64 h-64 max-w-xs  overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out ">
                  <a href={val.link} target="_blank" rel="noreferrer">
                    <img src={val.image} alt={val.alt} className="max-h-full" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-white m-auto p-auto">
        <h1 className="flex py-5  md:px-10 px-5 mx-5 font-bold text-4xl text-gray-800">
          Best e-resources for Muslims
        </h1>
        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
          <div className="flex flex-nowrap  md:ml-20 ml-40">
            {esources.map((val, id) => (
              <div className="inline-block px-3" key={id}>
                <div className="flex justify-center items-center w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                  <a
                    href={val.website}
                    className="text-emerald-600 font-bold text-2xl"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {val.name}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Sourcecard;
