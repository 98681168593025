import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Newsletter from "./Newsletter";

function Mailchimp() {
  const url = process.env.REACT_APP_MAILCHIMP_URL;
  if (!url) {
    return null;
  }
  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <div className="text-center">
          <Newsletter
            name="text"
            validate={(formData) => {
              subscribe(formData);
            }}
          />
          {status === "sending" && (
            <div style={{ color: "blue" }}>sending...</div>
          )}
          {status === "error" && (
            <div
              style={{ color: "red" }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && (
            <div style={{ color: "green" }}>Subscribed !</div>
          )}
        </div>
      )}
    />
  );
}
export default Mailchimp;
