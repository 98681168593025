import Navigation from "./reusable/Navigation";
import Hero from "./home/Hero";
import Footer from "./reusable/Footer";
import Main from "./home/Main";
import Mailchimp from "./reusable/Mailchimp";
import Prayertimes from "./prayertimes/Prayertimes";

import HijriGregoriandates from "./hijritogregorian/HijriGregoriandates";
import DataStat from "./datastats/DataStat";

export default function Home() {
  return (
    <div className="App mx-auto container">
      <Navigation />
      <Prayertimes />
      <Hero />
      <DataStat />
      <HijriGregoriandates />
      <Main />
      <Mailchimp />
      <Footer />
    </div>
  );
}
