import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

function App() {
  const [url, setUrl] = useState("");
  const [shortendUrl, setShortenedUrl] = useState("");

  const shortenUrl = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://api.shrtco.de/v2/shorten?url=${url}`
      );
      const data = await response.json();
      setShortenedUrl(data.result.full_short_link);
    } catch (e) {
      alert(e);
    }
  };

  return (
    <div className="app">
      <div className="shortener">
        <h2 className="text-left font-bold">URL shortener</h2>

        <form className="flex items-center" onSubmit={shortenUrl}>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              type="text"
              id="voice-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Paste long link here"
              required
            />
          </div>
          <button
            type="submit"
            className="items-center linkbutton py-2.5 px-3 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
          </button>
        </form>

        {/* Section to view shortened URLS */}
        {shortendUrl && (
          <div className="shortener__viewShot">
            {shortendUrl}
            <CopyToClipboard text={shortendUrl}>
              <button
                onClick={() => alert("The URL has been copied")}
                className="p-3"
              >
                copy
              </button>
            </CopyToClipboard>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
