import React from "react";

import Navigation from "./reusable/Navigation";
import Footer from "./reusable/Footer";
import Sourceshero from "./sources/Sourceshero";
import Sourcecard from "./sources/Sourcecard";

function Sources() {
  return (
    <div className="App mx-auto container">
      <Navigation />
      <Sourceshero />
      <Sourcecard />
      <Footer />
    </div>
  );
}

export default Sources;
