import React from "react";
import { Navbar } from "flowbite-react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo/1.png";

export default function Navigation() {
  return (
    <Navbar fluid={true} rounded={true}>
      <Navbar.Brand href="https://www.halalbaqqal.com/">
        <img src={Logo} className="mr-3 h-14 sm:h-18" alt="Halal Baqqal Logo" />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          HALAL BAQQAL
        </span>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Link className="text-xl" to="/">
          Home
        </Link>
        <Link className="text-xl" to="/Sources">
          Sources
        </Link>
        <Link className="text-xl" to="/Tools">
          Tools
        </Link>
        <Link className="text-xl" to="/Blog">
          Blog
        </Link>
        <Link className="text-xl" to="/About">
          About
        </Link>
      </Navbar.Collapse>
    </Navbar>
  );
}
