import React from "react";
import Navigation from "./reusable/Navigation";
import Footerr from "./reusable/Footer";

const Privacy = () => {
  return (
    <div className="App container mx-auto test">
      <Navigation />
      <>
        <section className="bg-white dark:bg-gray-900">
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
              <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                Halal Baqqal Privacy policy
              </h2>
              <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
                We care about your privacy. Please read our pravacy policy to
                make sure that it meets your expectations.
              </p>
            </div>

            <article className="p-6 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <div className="flex justify-between items-center mb-5 text-gray-500"></div>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                At Halal Baqqal, accessible from{" "}
                <a
                  href="https://www.halalbaqqal.com"
                  className="hover:text-blue-700"
                >
                  https://www.halalbaqqal.com
                </a>
                , one of our main priorities is the privacy of our visitors.
                This Privacy Policy document contains types of information that
                is collected and recorded by Halal Baqqal and how we use it. If
                you have additional questions or require more information about
                our Privacy Policy, do not hesitate to contact us. This Privacy
                Policy applies only to our online activities and is valid for
                visitors to our website with regards to the information that
                they shared and/or collect in Halal Baqqal. This policy is not
                applicable to any information collected offline or via channels
                other than this website.
              </p>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.The personal information that you are
                asked to provide, and the reasons why you are asked to provide
                it, will be made clear to you at the point we ask you to provide
                your personal information.If you contact us directly, we may
                receive additional information about you such as your name,
                email address, phone number, the contents of the message and/or
                attachments you may send us, and any other information you may
                choose to provide.When you register for an Account, we may ask
                for your contact information, including items such as name,
                company name, address, email address, and telephone number.
              </p>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                We use the information we collect in various ways, including to:
              </p>
              <ul className="mb-5 font-light text-gray-500 dark:text-gray-400">
                <li>1. Provide, operate, and maintain our website</li>
                <li>2. Improve, personalize, and expand our website</li>
                <li>3. Understand and analyze how you use our website</li>
                <li>
                  4. Develop new products, services, features, and functionality
                </li>
                <li>
                  5. Communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates and other information relating to the website, and for
                  marketing and promotional purposes
                </li>
                <li>6. Send you emails</li>
                <li>7. Find and prevent fraud</li>
              </ul>
              <h2>Log Files</h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                Halal Baqqal follows a standard procedure of using log files.
                These files log visitors when they visit websites. All hosting
                companies do this and a part of hosting services' analytics. The
                information collected by log files include internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analyzing trends, administering the site, tracking users'
                movement on the website, and gathering demographic information
              </p>
              <h2>Cookies and Web Beacons</h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                Like any other website, Halal Baqqal uses 'cookies'. These
                cookies are used to store information including visitors'
                preferences, and the pages on the website that the visitor
                accessed or visited. The information is used to optimize the
                users' experience by customizing our web page content based on
                visitors' browser type and/or other information.
              </p>
              <h2>Google DoubleClick DART Cookie</h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                Google is one of a third-party vendor on our site. It also uses
                cookies, known as DART cookies, to serve ads to our site
                visitors based upon their visit to www.website.com and other
                sites on the internet. However, visitors may choose to decline
                the use of DART cookies by visiting the Google ad and content
                network Privacy Policy at the following URL –{" "}
                <a href="https://policies.google.com/technologies/ads">
                  https://policies.google.com/technologies/ads
                </a>
              </p>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                Some of advertisers on our site may use cookies and web beacons.
                Our advertising partners are listed below. Each of our
                advertising partners has their own Privacy Policy for their
                policies on user data.
              </p>

              <h2>Advertising Partners Privacy Policies</h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of Halal Baqqal.
              </p>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on Halal Baqqal,
                which are sent directly to users' browser. They automatically
                receive your IP address when this occurs. These technologies are
                used to measure the effectiveness of their advertising campaigns
                and/or to personalize the advertising content that you see on
                websites that you visit.Note that Halal Baqqal has no access to
                or control over these cookies that are used by third-party
                advertisers.
              </p>
              <h2>Third Party Privacy Policies</h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                Halal Baqqal's Privacy Policy does not apply to other
                advertisers or websites. Thus, we are advising you to consult
                the respective Privacy Policies of these third-party ad servers
                for more detailed information. It may include their practices
                and instructions about how to opt-out of certain options.You can
                choose to disable cookies through your individual browser
                options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers' respective websites.
              </p>
              <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                Under the CCPA, among other rights, consumers have the right to:
              </p>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                Request that a business that collects a consumer's personal data
                disclose the categories and specific pieces of personal data
                that a business has collected about consumers. Request that a
                business delete any personal data about the consumer that a
                business has collected. Request that a business that sells a
                consumer's personal data, not sell the consumer's personal data.
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>
              <h2>GDPR Data Protection Rights</h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>

              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                The right to access – You have the right to request copies of
                your personal data. We may charge you a small fee for this
                service. The right to rectification – You have the right to
                request that we correct any information you believe is
                inaccurate. You also have the right to request that we complete
                the information you believe is incomplete.The right to erasure –
                You have the right to request that we erase your personal data,
                under certain conditions.The right to restrict processing – You
                have the right to request that we restrict the processing of
                your personal data, under certain conditions.The right to object
                to processing – You have the right to object to our processing
                of your personal data, under certain conditions.The right to
                data portability – You have the right to request that we
                transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.If
                you make a request, we have one month to respond to you. If you
                would like to exercise any of these rights, please contact us.
              </p>
              <h2>Children's Information</h2>
              <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.Halal Baqqal does not knowingly collect any Personal
                Identifiable Information from children under the age of 13. If
                you think that your child provided this kind of information on
                our website, we strongly encourage you to contact us immediately
                and we will do our best efforts to promptly remove such
                information from our records.
              </p>

              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <span className="font-medium dark:text-white">
                    Halal Baqqal Team
                  </span>
                </div>
              </div>
            </article>
          </div>
        </section>
      </>
      <Footerr />
    </div>
  );
};

export default Privacy;
