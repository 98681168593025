import React from "react";
import Navigation from "./reusable/Navigation";
import Shortlinkhero from "./shortlink/Shortlinkhero";
import Shortlinkcard from "./shortlink/Shortlinkcard";
import Zakatcard from "./zakat/Zakatcard";
import Footer from "./reusable/Footer";

function Tools() {
  return (
    <div className="App mx-auto container">
      <Navigation />
      <Shortlinkhero />
      <Shortlinkcard />
      <Zakatcard />
      <Footer />
    </div>
  );
}

export default Tools;
