import React, { useState, useEffect } from "react";

function App() {
  const [prayerTimes, setPrayerTimes] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPrayerTimes = async (latitude, longitude) => {
      try {
        const response = await fetch(
          `https://api.aladhan.com/v1/timings?latitude=${latitude}&longitude=${longitude}&method=2`
        );
        const data = await response.json();
        setPrayerTimes(data.data.timings);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    const getCoordinates = () => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          fetchPrayerTimes(latitude, longitude);
        },
        (error) => {
          setError(error.message);
          setIsLoading(false);
        }
      );
    };

    getCoordinates();
  }, []);

  return (
    <div className="flex p-3 justify-center">
      {isLoading ? (
        <p className="p-3 font-semibold text-green-500">
          Loading prayer times...
        </p>
      ) : error ? (
        <p className="p-3 font-semibold text-red-500">Error: {error}</p>
      ) : (
        <div>
          <ul className="flex flex-col md:flex-row">
            <li className="p-3 font-bold">Prayer Times:</li>
            <li className="p-3">Fajr: {prayerTimes.Fajr}</li>
            <li className="p-3">Sunrise: {prayerTimes.Sunrise}</li>
            <li className="p-3">Dhuhr: {prayerTimes.Dhuhr}</li>
            <li className="p-3">Asr: {prayerTimes.Asr}</li>
            <li className="p-3">Maghrib: {prayerTimes.Maghrib}</li>
            <li className="p-3">Isha: {prayerTimes.Isha}</li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default App;
